// AuthLayout.js
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const AuthLayout = ({ children }) => {
  return (
    <React.Fragment>
      <Row>
        <Col md="6" lg="6">
          <Wallpaper>
            <img className='login-logo' src="assets/images/logo/bg-white-removebg.png" alt="wallpaper" />    
          </Wallpaper>
        </Col>
        <Col md="6" lg="6">
          <LoginCard>
            {children}
          </LoginCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AuthLayout;

const Wallpaper = styled.div`
  background: linear-gradient(90deg, var(--button-gradient-start) 0%, var(--button-gradient-end) 100%);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  & .login-logo{
    width: 250px;
    aspect-ratio: 1/1;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const LoginCard = styled.div`
  width: 100%;
  background-color: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  & .card-block{
    padding: 1em !important;
    border: none !important;
  }

  & .card-block .title{
    text-align: center;
    margin-bottom: 2em;
  }

  & .caption a{
    color: var(--main-color) !important;
  }

  & .caption a:hover{
    color: var(--primary-color) !important;
  }
`;
