import React from 'react';
import InnerBanner from '../elements/InnerBanner';
import { Col, Container, Row } from 'react-bootstrap';
import { Section } from '../../Styled/GlobalStyle';
import styled from 'styled-components';
import BlogSection from '../elements/BlogSection';
import Testimonial from '../elements/Testimonial';
import ProductCard from '../elements/ProductCard';
import { Productlist } from '../global/GlobalVariable';
import ReactOwlCarousel from 'react-owl-carousel';

const Products = () => {

    // OwlCarousel Responsive //
    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 3, // Show 2 cards at 500px and up
        },
        1000: {
            items: 4, // Show 3 cards at 1000px and up
        },
    };

    return (
        <React.Fragment>
            <InnerBanner title="Products"/>
            <Section>
                <Container>
                    <h4 className="title fw-bold">Products</h4>
                    
                    <Row className='mt-5'>
                        {
                            Productlist.map((data,index) => (
                                <Col key={`pro-list-${index}`} sm="12" md="3" lg="3">
                                    <ProductCard name={data.name} des={data.desc} image={data.image}/>
                                </Col>
                            ))
                        }
                    </Row>
    
                </Container>
            </Section>
            
            <Section>
                <Container>
                    <h4 className="title fw-bold">Related products</h4>

                    <ReactOwlCarousel className='mt-5' responsive={responsiveOptions}>
                        {
                            Productlist.map((data,index) => (
                                <div className='item' key={`pro-list-${index}`}>
                                    <ProductCard name={data.name} des={data.desc} image={data.image}/>
                                </div>
                            ))
                        }   
                    </ReactOwlCarousel>
                   

                </Container>
            </Section>


         
        </React.Fragment>
    );
}

export default Products;


const Content = styled.p`
    margin-top: 2em;
    font-size: 18px;
`

const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
   

    & img{
        width: 100px;
        aspect-ratio: 1/1;
    }
    & .title{
        font-size: 20px;
        text-align: center;
        margin: 1em 0;
        text-transform: uppercase;
        font-weight: 700;
    }
    & .content{
        text-align: justify;
        font-size: 18px;
    }
`