import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Section } from '../../Styled/GlobalStyle';
import styled from 'styled-components';

const BlogSection = () => {
    return (
        <Section>
            <Container>
                <Row>
                    <Col sm="12" md="6" lg="6">
                        <BlogContainer style={{backgroundImage: "url('assets/images/chef-decoration.jpg')"}}>
                            <div className='content'>
                                <h4>Art on a plate</h4>
                                <p>
                                    Chefs often refer to plating as “culinary art.” It’s not just about taste but also about creating visually appealing dishes that stimulate all the senses.
                                </p>
                            </div>
                        </BlogContainer>
                    </Col>
                    <Col sm="12" md="6" lg="6">
                        <BlogContainer style={{backgroundImage: "url('assets/images/nursing-staff.jpg')"}}>
                            <div className='content'>
                                <h4>Care Beyond Medicine</h4>
                                <p>
                                Nursing is more than just administering treatments—it's about providing holistic care that touches both the body and the soul. Nurses blend clinical expertise with compassion, ensuring that each patient feels supported, understood, and cared for on every level.
                                </p>
                            </div>
                        </BlogContainer>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

export default BlogSection;


const BlogContainer = styled.div`
    height: 100%;
    border-radius: 5px;
    padding: 50px 41px;
    position: relative;
    background-position: center;
    background-size: cover;
    
    & .content{
        position: relative;
        z-index: 99;
    }

    & h4{
        font-size: 25px;
        font-weight: 700;
        color: var(--dark-text-color);
    }

    & p{
        font-size: 18px;
        font-weight: 400;
        margin-top: 28px;
        color: var(--dark-text-color);
    }

    &::after{
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
    }
`