import React from 'react';
import { Container, Nav, Badge } from 'react-bootstrap';
import Searchbar from '../elements/Searchbar';
import styled from 'styled-components';
import { Heart, Cart } from 'react-bootstrap-icons';




const TopNav = () => {
    return (
        <Container>
            <Nav className='align-items-center justify-content-between py-3'>
                <img src="assets/images/logo/bg-white-removebg.png" width="90" alt="logo" />
                    <Searchbar/>
                    <IconGrp>
                        <BadgeContainer>
                            <Heart />
                            <Badge pill bg="danger">0</Badge> {/* Example count */}
                        </BadgeContainer>
                        <BadgeContainer>
                            <Cart />
                            <Badge pill bg="danger">0</Badge> {/* Example count */}
                        </BadgeContainer>
                    </IconGrp>
            </Nav>
        </Container>
    );
}

export default TopNav;



const IconGrp = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg{
        font-size: 20px;
        margin: 0 10px;
    }
`;

const BadgeContainer = styled.a`
    position: relative;
    margin: 0 10px;

    & .badge {
        position: absolute;
        top: -8px;
        right: 0px;
        font-size: 12px;
    }

`;