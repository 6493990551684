


export const SliderList = [
    {
        id: 1,
        image: "slider.png"
    },
    {
        id: 2,
        image: "labourday.jpg"
    },
    {
        id: 3,
        image: "cheftools.jpg"
    }
]


export const WhyChooseTab = [
    {
        id: 1,
        title: "quality products",
        content: "We are committed to offering only the best, ensuring that every product meets the highest standards of quality and safety.",
        cls: "leftside"
    },
    {
        id: 2,
        title: "Expertise",
        content: "With a deep understanding of the industries we serve, we carefully select products that truly enhance your training experience.",
        cls: "leftside"
    },
    {
        id: 3,
        title: "Customer Support",
        content: "Our team is here to assist you with any questions or needs, ensuring you have the right tools for your success.",
        cls: "leftside"
    }
];


export const OurProductTab = [
    {
        id: 1,
        title: "Trade Course Supplies",
        content: "We offer a comprehensive range of tools and equipment designed for hands-on learning in various trades. From carpentry to plumbing, our products are sourced from top manufacturers to ensure durability and performance.",
        cls: "rightside"
    },
    {
        id: 2,
        title: "Nursing Equipment",
        content: "Understanding the critical role that nurses play, we provide essential nursing supplies that are both practical and reliable. Our range includes everything from stethoscopes to patient care tools, designed to help you provide the best care possible.",
        cls: "rightside"
    },
    {
        id: 3,
        title: "Chef Equipment",
        content: "For those passionate about the culinary arts, we offer professional-grade kitchen equipment. Whether you’re learning the basics or refining your skills, our chef supplies are crafted to meet the demands of any kitchen environment.",
        cls: "rightside"
    }
];

export const Productlist = [
    {
        id: 1,
        cat: "hospital",
        name: "Refurbished Hospital Beds",
        desc: "YA-D6-3 hospital bed has marked another generation for Medik’s ICU bed design",
        image: "bed.png",
    },
    {
        id: 2,
        cat: "hospital",
        name: "oxygen machine",
        desc: "YA-D6-3 hospital bed has marked another generation for Medik’s ICU bed design.",
        image: "oxygen-concentrator.png",
    },
    {
        id: 3,
        cat: "hospital",
        name: "diagnosis equipment",
        desc: "YA-D6-3 hospital bed has marked another generation for Medik’s ICU bed design...",
        image: "diagnosis-equipement.png",
    },
    {
        id: 4,
        cat: "hospital",
        name: "surgical equipment",
        desc: "YA-D6-3 hospital bed has marked another generation for Medik’s ICU bed design...",
        image: "surgical-tools.png",
    }

];



export const TestimonialList = [
    {
        id: 1,
        name: "John Smith",
        designation: "Manager",
        image: "pic-1.jpg",
        message: "Great selection of chef essentials!  They have everything from uniforms to knives and spatulas.  Prices are fair, and the staff is knowledgeable.  Only downside is they don't have an online store, so you have to visit their shop in person.  Still, a good experience overall."
    },
    {
        id: 2,
        name: "Richel Kol",
        designation: "Manager",
        image: "pic-2.jpg",
        message: "Great selection of chef essentials!  They have everything from uniforms to knives and spatulas.  Prices are fair, and the staff is knowledgeable.  Only downside is they don't have an online store, so you have to visit their shop in person.  Still, a good experience overall."
    },

]