import React from 'react';
import Slider from '../elements/Slider';
import { Card, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Section } from '../../Styled/GlobalStyle';
import AboutSection from '../elements/AboutSection';
import styled from 'styled-components';
import BlogSection from '../elements/BlogSection';
import Testimonial from '../elements/Testimonial';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = () => {

    const cards= [
        {
            id: 1,
            name: "Hospitality",
            image: "chef-logo.png",
        },
        {
            id: 2,
            name: "Trade Course",
            image: "construction-worker.png"
        },
        {
            id: 3,
            name: "Medical",
            image: "nursing.png"
        },
        {
            id: 4,
            name: "Other",
            image: "chef-de.jpg"
        }
    ];

    // OwlCarousel Responsive //
    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 3, // Show 2 cards at 500px and up
        },
        1000: {
            items: 4, // Show 3 cards at 1000px and up
        },
    };


    return (
        <React.Fragment>
            <Slider/>
            <Section>
                <Container>
                   
                    <ReactOwlCarousel className='owl-theme' loop margin={10} responsive={responsiveOptions} nav>
                    {
                        cards.map((data,index) => (
                            <div key={`card-${index}`} className="item">
                                <Card className='text-center'>
                                    <Card.Body className='position-relative'>
                                        <ImgLogo src="assets/images/logo.png" alt="logo" className='mts-logo' />
                                        <img src={`/assets/images/${data.image}`} className='w-100' alt="chef logo" />
                                    </Card.Body>
                                    <Card.Footer>
                                        <NavLink to="#" className="fw-bold">{data.name}</NavLink>
                                    </Card.Footer>
                                </Card>
                            </div>
                        ))
                    }
                    </ReactOwlCarousel>
                   
                </Container>
            </Section>

            <AboutSection/>
            <BlogSection />
            <Testimonial/>
        </React.Fragment>
    );
}

export default Home;


const ImgLogo = styled.img`
    width: 50px !important;
    position: absolute;
    top: 10px;
    left: 10px;
`