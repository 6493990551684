// Signup.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import AuthLayout from '../elements/AuthLayout';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

export function Signup() {
  return (
    <AuthLayout>
      <div className='card-block'>
        <h4 className="title">Sign Up</h4>
        <FormContainer>
            <Row>
                <Col md="6">
                    <label htmlFor="fname" className="form-label">First Name</label><br />
                    <input type="text" id="fname" name='fname' className="form-input" />
                </Col>
                <Col md="6">
                    <label htmlFor="lname" className="form-label">Last Name</label><br />
                    <input type="text" id="lname" name='lname' className="form-input" />
                </Col>
            </Row>
            <label htmlFor="email" className="form-label">Email</label><br />
            <input type="email" id="email" className="form-input" />
            <br />
            <label htmlFor="mobile" className="form-label">Mobile</label><br />
            <input type="text" id="mobile" name='mobile' className="form-input" />
            <br />
            <label htmlFor="password" className="form-label">Password</label><br />
            <input type="password" id="password" name='password' className="form-input" />
            <br />
            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label><br />
            <input type="password" id="confirmPassword" name='confirm_password' className="form-input" />
            <br />
            <button className="btn-gradient w-100">Sign Up</button>
        </FormContainer>
        <p className='caption'>If you have an account <NavLink to="/login">Login</NavLink></p>
      </div>
    </AuthLayout>
  );
}

export default Signup;

const FormContainer = styled.form`
  width: 100%;
  padding: .5em;

  & .form-input{
    width: 100%;
    margin-bottom: 1em;
    padding: .5em 1em;
    border-radius: .3em;
    border: 1px solid #D9D9D9;
  }

  & .form-input:focus{
    border: 1px solid #D9d9d9;
  }
`;
