import React from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import styled from 'styled-components';

const InnerBanner = ({title}) => {
    return (
        <Banner style={{backgroundImage: "url('assets/images/banner.jpg')"}}>
            <Container className='position-relative z-3'>
                <Title className='Title'>{title}</Title>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item active style={{color: "#00ADC6"}}>{title}</Breadcrumb.Item>
                </Breadcrumb>
            </Container>
        </Banner>
    );
}

export default InnerBanner;


const Banner = styled.div`
    width: 100%;
    padding: 60px 88px;
    position: relative;
    color: #fff;
    background: center;
    background-size: cover;

    &::after{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.8);
    }
    & .breadcrumb-item a{
        color: #fff;
    }
    & .breadcrumb-item a.active{
        color: #fff;
    }
`

const Title = styled.h4`
    font-size: 35px;
    font-weight: 600;
    color: #fff;
   
`
