import React from 'react';
import { Search } from 'react-bootstrap-icons';
import styled from 'styled-components';

const Searchbar = () => {
    return (
        <SearchContainer>
            <SearchLabel htmlFor="search"><Search/> </SearchLabel>
            <SearchInput type='text' id='search' placeholder='Search...'/>
        </SearchContainer>
    );
}

export default Searchbar;

const SearchContainer = styled.div`
    position: relative;
    width: 50%;

    @media (max-width: 495px) {
        display: none;
    }
`;
const SearchInput = styled.input`
    width: 100%;
    border: 1px solid #CDC9C9;
    border-radius: 50px;
    padding: 10px 10px 10px 50px;
`;

const SearchLabel = styled.label`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;

   
`