import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
    At,
    Facebook, 
    GeoAltFill, 
    Linkedin, 
    TelephoneFill, 
    TwitterX 
} from 'react-bootstrap-icons';

const Footer = () => {

    return (
        <footer>
            <Container>
                <Row>
                    <Col sm="12" md="3" lg="3" className='mb-5'>
                        <FootLogo>
                            <img src="assets/images/logo/bg-white-removebg.png" alt="logo" />
                            {/* <h4>Man Training Suppliers</h4> */}
                        </FootLogo>
                        <FootContent>
                            Welcome to Men Training Supplies, your one-stop destination for premium training supplies tailored to the needs of men pursuing careers in trades, nursing, and the culinary arts.
                        </FootContent>
                        <SocialIcon>
                            <NavLink to="#" target='_blank'><Facebook size={24} /></NavLink>
                            <NavLink to="#" target='_blank'><TwitterX size={24}/></NavLink>
                            <NavLink to="#" target='_blank'><Linkedin size={24}/></NavLink>
                        </SocialIcon>
                    </Col>
                    <Col sm="12" md="3" lg="3" className='mb-5'>
                        <h4 className='foot-title'>Popular Categories</h4>
                        <ul className='footer-link'>
                            <li><NavLink to="">Chef Toolkit</NavLink></li>
                            <li><NavLink to="">Student Toolkit</NavLink></li>
                            <li><NavLink to="">Uniform</NavLink></li>
                        </ul>
                    </Col>
                    <Col sm="12" md="3" lg="3" className='mb-5'>
                        <h4 className='foot-title'>Quick Link</h4>
                        <ul className='footer-link'>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><NavLink to="about">About us</NavLink></li>
                            <li><NavLink to="contact">Contact us</NavLink></li>
                            <li><NavLink to="terms">Terms & Conditions</NavLink></li>
                            <li><NavLink to="policy">Privacy Policy</NavLink></li>
                        </ul>
                    </Col>
                    <Col sm="12" md="3" lg="3">
                        <h4 className='foot-title'>Contact Us</h4>
                        <ul className='footer-link'>
                            <li><NavLink to=""><GeoAltFill size={20}/> H.NO. - 1st Block, new city, CN-560016</NavLink></li>
                            <li><NavLink to="tel:8252476211"><TelephoneFill size={20}/> 82 524 762 11</NavLink></li>
                            <li><NavLink to="mailto:example@gmail.com"><At size={20}/> example@gmail.com</NavLink></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;

const FootLogo = styled.div`
    text-align: start;
    display:flex;
    flex-direction: column;
    align-items: center;


    & img{
        width: 210px;
        aspect-ratio: 1/1;
    }

    & h4{
        font-size: 20px;
        font-weight: 600;
        margin-top: 9px; 
    }
`;

const FootContent = styled.p`
    width: 100%;
    // margin-top: 25px;
    text-align: justify;
`

const SocialIcon = styled.div`
    margin-top:47px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    & a:hover{
        color: #00ADC6;
    }

    @media (max-width: 991px) {
        justify-content: space-between;
    }
`