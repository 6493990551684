import React from 'react';
import { NavLink } from 'react-router-dom';
import AuthLayout from '../elements/AuthLayout';
import styled from 'styled-components';


export function ForSignup(){
    return (
        <AuthLayout>
        <div className='card-block'>
            <h4 className="title">Choose Role type</h4>
            <FormContainer>
                <select className='form-input'>
                    <option value="">Choose for ...</option>
                    <option value="tafe">TAFE</option>
                    <option value="rto">RTO</option>
                </select>
                <NavLink to="/signup" className="btn-gradient w-100 mb-1">Next</NavLink>
                <NavLink to="/login" className="btn-outline-gradient w-100">Back</NavLink>
            </FormContainer>
        </div>
      </AuthLayout>    
    
    );
}

export default ForSignup;



const FormContainer = styled.form`
    width: 100%;
    padding: .5em;

    & .form-label{

    }
    & .form-input{
        width: 100%;
        margin-bottom: 1em;
        padding: .5em 1em;
        border-radius: .3em;
        border:1px solid #D9D9D9;
    }
    & .form-input:focus{
        border: 1px solid #D9d9d9;
    }
`