import React from 'react';
import { Section } from '../../Styled/GlobalStyle';
import { Col, Container, Image, Row } from 'react-bootstrap';
import styled from 'styled-components';
import TabContainer from './TabContainer';
import { OurProductTab, WhyChooseTab } from '../global/GlobalVariable';

const AboutSection = () => {
    return (
        <React.Fragment>
            <Section>
                <Container>
                    <Row>
                        <Col sm="12" md="6" lg="6">
                            <Content>
                                <h4>About us</h4>
                                <p>
                                    Welcome to Men Training Supplies, your one-stop destination for premium training supplies tailored to the needs of men pursuing careers in trades, nursing, and the culinary arts.
                                    <br /> <br />
                                    Our mission is to empower individuals with the tools and equipment they need to succeed in their chosen fields. Whether you're aspiring to become a master tradesman, a skilled nurse, or a top-tier chef, we provide the highest quality products to support your journey.
                                </p>
                            </Content>
                        </Col>
                        <Col sm="12" md="6" lg="6">
                            <ImageContainer>
                                <Image src="assets/images/aboutus.png" alt="aboutimage"/>
                            </ImageContainer>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Section>
                <Container>
                    <Row>
                        <Col sm="12" md="6" lg="6" className='position-relative ver-line'>
                            <h4 className='text-center fw-bolder text-uppercase'>Why Choose Us</h4>
                            <CenterContainer>
                            {
                                WhyChooseTab.map((data, index) => (
                                    <TabContainer key={`tab-${index}`} title={data.title} content={data.content}/>
                                ))
                            }
                            </CenterContainer>
                        </Col>
                        
                        <Col sm="12" md="6" lg="6" className='sm-ver-line'>
                            <h4 className='text-center fw-bolder text-uppercase md-mb-3-all sm-mt-3-all'>Our products</h4>
                            <CenterContainer>
                            {
                                OurProductTab.map((data, index) => (
                                    <TabContainer key={`tab-${index}`} title={data.title} content={data.content} cls={data.cls}/>
                                ))
                            }
                            </CenterContainer>
                           
                        </Col>
                    </Row>
                </Container>
            </Section>
        </React.Fragment>
    );
}

export default AboutSection;

const Content = styled.div`
    padding: 1em;

    & h4{
        font-size: 29px;
        font-weight: 600;
        font-decoration: uppercase;
        text-align: center;
        text-transform: uppercase;
    }
    & p{
        margin-top:38px;
        font-size: 18px;
        line-spacing: 1.5;
        word-spacing: 4px;
    }
`;

const ImageContainer = styled.div`
    & img{
        border-radius: 10px;
        width: 100%;
    }
`;

const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`

