// Login.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import AuthLayout from '../elements/AuthLayout';
import styled from 'styled-components';

export function Login() {
  return (
    <AuthLayout>
      <div className='card-block'>
        <h4 className="title">Login</h4>
        <FormContainer>
            <label htmlFor="username" className="form-label">Username</label><br />
            <input type="email" id="username" name='email' className="form-input" />
            <br />
            <label htmlFor="password" className="form-label">Password</label><br />
            <input type="password" id="password" name='password' className="form-input" />
            <br />
            <button className="btn-gradient w-100">Login</button>
        </FormContainer>
        <p className='caption'>If you don't have an account <NavLink to="/forsignup">Sign up</NavLink></p>
      </div>
    </AuthLayout>
  );
}

export default Login;

const FormContainer = styled.form`
  width: 100%;
  padding: .5em;

  & .form-input{
    width: 100%;
    margin-bottom: 1em;
    padding: .5em 1em;
    border-radius: .3em;
    border: 1px solid #D9D9D9;
  }

  & .form-input:focus{
    border: 1px solid #D9d9d9;
  }
`;
