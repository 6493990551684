import styled from "styled-components";


export const Section = styled.section`
    padding: 3em 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
    &.bg-image{
        position:relative;
    }

    &.bg-image::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.7);
    }
`;


