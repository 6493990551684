import React from 'react';
import InnerBanner from '../elements/InnerBanner';
import { Col, Container, Row } from 'react-bootstrap';
import { Section } from '../../Styled/GlobalStyle';
import styled from 'styled-components';
import BlogSection from '../elements/BlogSection';
import Testimonial from '../elements/Testimonial';

const About = () => {
    return (
        <React.Fragment>
            <InnerBanner title="About Us"/>
            <Section>
                <Container>
                    <h4 className="title fw-bold">About us</h4>

                    <Content>
                        Welcome to Men Training Supplies, your one-stop destination for premium training supplies tailored to the needs of men pursuing careers in trades, nursing, and the culinary arts.
                    </Content>
                    <Content>
                        Our mission is to empower individuals with the tools and equipment they need to succeed in their chosen fields. Whether you're aspiring to become a master tradesman, a skilled nurse, or a top-tier chef, we provide the highest quality products to support your journey.
                    </Content>
                </Container>
            </Section>

            <Section>
                <Container>
                    <Row>
                        <Col sm="12" md="4" lg="4">
                            <Block>
                                <img src="assets/images/target.png" alt="target" />
                                <h4 className="title">Mission</h4>
                                <p className='content'>
                                Our mission is to empower individuals with the tools and equipment they need to succeed in their chosen fields.
                                </p>
                            </Block>
                        </Col>
                        <Col sm="12" md="4" lg="4">
                            <Block>
                                <img src="assets/images/vision.png" alt="target" />
                                <h4 className="title">Vision</h4>
                                <p className='content'>
                                Our mission is to empower individuals with the tools and equipment they need to succeed in their chosen fields.
                                </p>
                            </Block>
                        </Col>
                        <Col sm="12" md="4" lg="4">
                            <Block>
                                <img src="assets/images/return-to-the-past.png" alt="target" />
                                <h4 className="title">History</h4>
                                <p className='content'>
                                Our mission is to empower individuals with the tools and equipment they need to succeed in their chosen fields.
                                </p>
                            </Block>
                        </Col>
                    </Row>
                </Container>
            </Section>

            <BlogSection/>
            <Testimonial/>
        </React.Fragment>
    );
}

export default About;


const Content = styled.p`
    margin-top: 2em;
    font-size: 18px;
`

const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
   

    & img{
        width: 100px;
        aspect-ratio: 1/1;
    }
    & .title{
        font-size: 20px;
        text-align: center;
        margin: 1em 0;
        text-transform: uppercase;
        font-weight: 700;
    }
    & .content{
        text-align: justify;
        font-size: 18px;
    }
`