import React from 'react';
import { Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import TopNav from './TopNav';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';


const Header = () => {

    return (
        <React.Fragment>
            <TopNav/>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container >
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse className="justify-content-between">
                        <Nav className="me-auto">
                            <NavLink to="/">Home</NavLink>
                            <NavLink to="about">About us</NavLink>
                            <NavDropdown title="Products" id="basic-nav-dropdown" className='position-unset'>
                                <DropNavTab>
                                    <NavCol>
                                        <h4>Hospital</h4>
                                        <NavList>
                                            <NavLink to="product">Kitchen Tool</NavLink>
                                            <NavLink to="product">Student Toolkit</NavLink>
                                            <NavLink to="product">Uniform</NavLink>
                                        </NavList>
                                    </NavCol>
                                    <NavCol>
                                        <h4>Medical</h4>
                                        <NavList>
                                            <NavLink>Nursing Equipement</NavLink>
                                            <NavLink>Student Toolkit</NavLink>
                                            <NavLink>Uniform</NavLink>
                                        </NavList>
                                    </NavCol>
                                    <NavCol>
                                        <h4>Trade Course</h4>
                                        <NavList>
                                            <NavLink>Trade Toolkit</NavLink>
                                            <NavLink>Uniform</NavLink>
                                        </NavList>
                                    </NavCol>
                                </DropNavTab>
                            </NavDropdown>
                            <NavLink to="#features">Blog</NavLink>
                            <NavLink to="#features">Contact us</NavLink>
                            <NavLink to="/login" className="btn-gradient">TAFE / RTO LOGIN</NavLink>
                            
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    );
}

export default Header;


const DropNavTab = styled.div`
    display: flex;
    padding: 19px;
`;

const NavCol = styled.div`
    margin-right: 1em;
    & h4{
        font-size: 18px;
        font-weight: 600;
        color: var(--text-color);
    }
`;

const NavList = styled.div`
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    & a{
        margin-top: 10px;
    }
`