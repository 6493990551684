import React from 'react';
import { Section } from '../../Styled/GlobalStyle';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { TestimonialList } from '../global/GlobalVariable';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Testimonial = () => {

    // OwlCarousel Responsive //
    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 1, // Show 2 cards at 500px and up
        },
        1000: {
            items: 1, // Show 3 cards at 1000px and up
        },
    };
    return (
        <Section className='bg-image' style={{backgroundImage:"url('assets/images/bg-2.jpg')"}}>
            <Container>
                <h4 className='section-title text-white position-relative z-3'>Testimonial</h4>
                <ReactOwlCarousel className='owl-theme' loop margin={10} responsive={responsiveOptions} autoPlay={true}>
                {
                    TestimonialList.map((data,index) => (
                        <Row key={`testimonial-${index}`} className='mt-5'>
                            <Col sm="12" md="4" lg="4">
                                <ImageBlock>
                                    <img src={`assets/images/${data.image}`} alt="profile" />
                                </ImageBlock>
                            </Col>
                            <Col sm="12" md="8" lg="8">
                                <div className="test-content-block">
                                    <h5 className="pr-name">{data.name}</h5>
                                    <p className='designation'>{data.designation}</p>
                                    <div className="message">
                                        <p>{data.message}</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    ))
                }
                </ReactOwlCarousel>
            </Container>
        </Section>
    );
}

export default Testimonial;


const ImageBlock = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & img{
        width: 150px !important;
        aspect-ratio: 1/1;
        border-radius: 50%;
        object-fit: cover;
    }

  
`