import React from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';

function ProductCard({name, des, image}) {
    
    return (
        <React.Fragment>
             <Card>
                <Card.Body>
                    <img src={`assets/images/${image}`} alt="products"/>
                    <ContentBlock>
                        <h4 className="title">{name}</h4>
                        <p className="descrip">{des}</p>
                    </ContentBlock>
                    <PriceBlock>
                        <p>Price: <span className='text-danger'>Ask</span></p>
                        <button className='btn-gradient'>Request for price</button>
                    </PriceBlock>
                </Card.Body>
             </Card>
        </React.Fragment>
    );
}

export default ProductCard;


const ContentBlock = styled.div`
    width: 100%;

    & .title {
        font-size: 16px;
        font-weight: 600;
        margin: .5em 0;
        text-transform: capitalize;
    }

    & .descrip {
        font-size: 12px;
        margin-bottom: .5em;
        color: #AEAEAE;
    }

`;

const PriceBlock = styled.div`
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
`