import React from 'react';
import { Carousel } from 'react-bootstrap';
import { SliderList } from '../global/GlobalVariable';


const Slider = () => {

    return (
        <React.Fragment>
            <Carousel dark-bs-theme="dark">
            {
                SliderList.map((data,index) => (
                    <Carousel.Item key={`slider-${index}`}>
                        <img src={`assets/images/${data.image}`} alt='slider' className='w-100'/>
                        <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))
            }
            </Carousel>
        </React.Fragment>
        
        
    );
}

export default Slider;
