import React from 'react';
import styled from 'styled-components';

const TabContainer = ({title, content, cls}) => {
    return (
        <React.Fragment>
            <Tab className={cls}>
                <h4>{title}:</h4>
                <p>{content}</p>
                <span className="dots"></span>
                <span className="line"></span>
            </Tab>
        </React.Fragment>
    );
}

export default TabContainer;


const Tab = styled.div`
    position: relative;
    display: flex;
    width: 506px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 4.5em ;
    flex-shrink: 0;
    background: linear-gradient(180deg, var(--button-gradient-start) 0%, var(--button-gradient-end) 100%);

    & h4{
        color: #FFF;
        font-size: 20px;
        font-weight: 800;
        line-height: normal;
        text-transform: capitalize;
    }

    & p{
        color: #FFF;
        font-size: 17px;
        line-height: normal;
        margin-top: 20px;
        word-spacing: 4px
    }

    & .dots {
        position: absolute;
        top: 50%;
        right: -83px;
        width: 16px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: var(--button-gradient-start);
        z-index: 99;
    }

    & .line {
        position: absolute;
        top: 54%;
        right: -73px;
        width:73px;
        height: 4px;
        background-color: var(--text-color);
    }    

    &.rightside .dots{
        border-right: 3px;
        position: absolute;
        top: 50%;
        left: -87px;
        width:16px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: var(--button-gradient-start);
        z-index: 99;
    }

    &.rightside .line {
        position: absolute;
        top: 53%;
        left: -72px;
        width:72px;
        height: 4px;
        background-color: var(--text-color);
    }

    @media (max-width: 1392px) {
        width: 416px;
    }

    @media (max-width: 1199px) {
        width: 416px;

        & .dots{
            right: -38px;
        }
        & .line{
            width: 35px;
            right: -35px;
        }
        &.rightside .dots{
            left: -42px;
        }

        &.rightside .line{
            width: 32px;
            left: -32px;
        }
    }

    @media (max-width: 991px) {
        width: 296px;
    }

    @media (max-width: 767px) {
        width: 100%;
        margin-top: 1.5em ;

        & .dots{
            right: -42px;
        }
        &.rightside .dots{
            right: -43px;
            left: unset;
        }
        &.rightside .line{
            width: 27px;
            right: -27px;
            left: unset;
        }
    }
    
    @media (max-width: 602px){
        & .dots, .line{
            display: none;
        }
    }
`
