import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/include/Header";
import Home from "./components/pages/Home";
import Footer from "./components/include/Footer";
import About from "./components/pages/About";
import Products from "./components/pages/Products";
import Login from "./components/pages/Login";
import React from "react";
import Signup from "./components/pages/Signup";
import ForSignup from "./components/pages/ForSignup";

function Layout() {

  const location = useLocation();
  const isAuthRoute = location.pathname === "/login" || location.pathname === "/signup" || location.pathname === "/forsignup";


  return (
    <React.Fragment>
      {!isAuthRoute && <Header/>}
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="about" element={<About/>}/>
        <Route path="product" element={<Products/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/forsignup" element={<ForSignup/>}/>
        <Route path="/signup" element={<Signup/>}/>
      </Routes>    
      {!isAuthRoute && <Footer/>}
    </React.Fragment>
  );
}


export default function App(){
  return (
    <BrowserRouter>
      <Layout/>
    </BrowserRouter>
  )
}

